import Vue from "vue";

/**
 * Mixin automatizing injection of a vuex state in a form
 *
 */

const injectStateMixin = <S, F>(
  state: S,
  defaultForm: F,
  getState: (state: S) => F | null
) =>
  Vue.extend({
    created() {
      this.form = { ...this.defaultForm, ...this.current };
    },
    data() {
      return {
        defaultForm: defaultForm as F,
        form: defaultForm as F
      };
    },
    methods: {
      inject(val: F): void {
        this.form = { ...this.defaultForm, ...val };
      }
    },
    computed: {
      current() {
        return getState(state);
      }
    },
    watch: {
      current(val: F): void {
        this.inject(val);
      }
    },
    destroyed() {
      this.form = { ...this.defaultForm };
    }
  });

export default injectStateMixin;
