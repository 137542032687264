














































import injectState from "@/mixins/injectState";
import { required, email } from "vuelidate/lib/validators";
import LayoutWithPageHeader from "@/components/layout/LayoutWithPageHeader.vue";
import Form from "@/components/form/Form.vue";

import FormField from "@/components/form/FormField.vue";
import FileUpload from "@/components/form/FileUpload.vue";
import { User } from "@/generated/graphql";
import mixins from "vue-typed-mixins";
import store from "@/store/index";
import { RootState } from "@/store/state";
import { ActionTypes } from "@/store/action-types";

const defaultContact = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  pictureUrl: ""
} as User;
export default mixins(
  injectState<RootState, User>(
    store.state,
    defaultContact,
    state => state.loggedUser
  )
).extend({
  components: {
    LayoutWithPageHeader,
    FileUpload,
    FormField,
    Form
  },
  validations: {
    form: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        email
      },
      pictureUrl: {}
    }
  },
  methods: {
    setLogoUrl(url: string) {
      this.form.pictureUrl = url;
    },
    async onSubmit() {
      await this.$storeTyped.dispatch(ActionTypes.PATCH_LOGGED_USER, this.form);
    }
  }
});
